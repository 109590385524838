<template>
  <v-container class="p-0" fluid>
    <v-row justify="space-around" class="p-0" align="center">
      <v-col cols="12" lg="5" md="5" sm="5" xl="5" xs="5">
        <v-dialog v-model="showCampaignDialog" width="70%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              :class="{ shake: shakeBtn }"
              v-bind="attrs"
              v-on="on"
              @click="shakeBtn = !shakeBtn"
              >Select Campaign</v-btn
            >
          </template>

          <v-card>
            <v-toolbar>
              <v-spacer></v-spacer>

              <v-btn icon @click="showCampaignDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <campaign-list
              :viewOnMonitoring="true"
              @select-campaign="setCampaign"
            ></campaign-list>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="12" lg="2" md="2" sm="2" xl="2" xs="2" v-if="campaign">
        <span>{{ campaign.name }}</span>
      </v-col>

      <v-col class="controller" cols="12" lg="5" md="5" sm="5" xl="5" xs="5">
        <p>VIEW</p>

        <v-btn
          v-for="btn in buttons"
          elevation="0"
          :key="btn.name"
          @click="setComponent(btn.cmp)"
          :class="{ active: btn.cmp === currentComponent }"
        >
          <v-icon color="black">{{ btn.icon }}</v-icon>
          {{ btn.name }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <keep-alive v-if="campaign">
        <component
          :currentCampaign="campaign"
          :is="currentComponent"
        ></component>
      </keep-alive>

      <v-skeleton-loader
        v-show="!campaign"
        min-height="500"
        width="100%"
        type="image"
      ></v-skeleton-loader>
    </v-row>

    <alert></alert>
  </v-container>
</template>

<script>
import MonitoringList from "@/components/monitoring/MonitoringList.vue";
import MonitoringMap from "@/components/monitoring/MonitoringMap.vue";
import CampaignList from "@/components/campaign/CampaignList.vue";

import { handleHttpError } from "@/helpers/utils.js";

import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapActions } = createNamespacedHelpers("campaigns");

export default {
  data() {
    return {
      currentComponent: "MonitoringMap",
      buttons: [
        { name: "Map", icon: "mdi-map-legend", cmp: "MonitoringMap" },
        { name: "Table", icon: "mdi-table-large", cmp: "MonitoringList" },
      ],

      showCampaignDialog: false,
      campaign: null,
      shakeBtn: true,
    };
  },

  components: { MonitoringList, MonitoringMap, CampaignList },

  computed: {
    ...mapGetters(["getCurrentCampaign"]),
  },

  methods: {
    ...mapActions(["fetchCampaign"]),

    setComponent(cmp) {
      this.currentComponent = cmp;
    },
    async setCampaign() {
      this.showCampaignDialog = false;

      this.campaign = await this.fetchCampaign(this.getCurrentCampaign);
    },
  },

  watch: {
    showCampaignDialog(state) {
      if (!state && !this.getCurrentCampaign) {
        handleHttpError("No Campaign was selected.");
        this.shakeBtn = true;
        return;
      }
      this.shakeBtn = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
}
.controller {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.controller p {
  margin: 0;
  padding-right: 10px;
}

.v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
  text-transform: none;
}
.active {
  background: #f68625 !important;
}

.v-skeleton-loader__image {
  height: 100% !important;
}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
.shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
