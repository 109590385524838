<template>
  <v-card width="100%" height="100%">
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <GmapMap
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      class="map"
      :options="mapOptions"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <v-card
          v-if="currentBillboard"
          elevation="0"
          class="mx-auto"
          max-width="350"
        >
          <v-card-text>
            <v-row dense v-viewer>
              <v-col
                v-for="img in currentBillboard.images"
                :key="img.id"
                cols="12"
                md="4"
              >
                <v-card>
                  <img
                    class="white--text align-end image"
                    gradient="to bottom, rgba(0,0,0,.1),rgba(0,0,0,.5)"
                    height="200px"
                    :src="img.path"
                    :lazy-src="img.path"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </gmap-info-window>
    </GmapMap>
  </v-card>
</template>

<script>
import { gmapApi } from "gmap-vue";

import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default {
  data() {
    const styles = [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ color: "#444444" }],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          { saturation: -100 },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          { color: "#80DEEA" },
          {
            visibility: "on",
          },
        ],
      },
    ];

    return {
      loading: false,
      infoOptions: { pixelOffset: { width: 0, height: -35 } },
      // popup window
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,

      mapOptions: {
        zoomControl: true,
        zoomControlOptions: { position: 9 },
        mapTypeControl: true,
        mapTypeControlOptions: { position: 11 },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: { position: 9 },
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: styles,
      },

      map: null,
      api: null,
      apiLoaded: false,

      zoom: 15,
      center: { lat: 5.623028, lng: -6.176527 },

      bbClusterer: null,
      bbMarkers: null,
      bounds: null,
      currentBillboard: null,
    };
  },

  props: {
    currentCampaign: {
      type: Object,
      default: () => ({
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        billboards: [
          {
            id: null,
            latitude: null,
            longitude: null,
            images: [{ path: null }],
          },
        ],
        planner: {
          user: {
            organization: null,
            planner_name: null,
          },
        },
        brand: {
          name: null,
        },
      }),
      required: true,
    },
  },

  computed: {
    google: gmapApi,
  },

  watch: {
    currentCampaign: {
      handler(val) {
        if (!val) return;
        this.currentBillboard = val;
        this.plotBillboards();
      },
    },
  },

  methods: {
    plotBillboards() {
      if (!this.currentCampaign.billboards.length) {
        this.bbClusterer.clearMarkers();
        return;
      }
      // show loader
      this.loading = true;
      // remove bbs
      this.bbClusterer.clearMarkers();

      this.bounds = new this.google.maps.LatLngBounds();

      this.bbMarkers = this.currentCampaign.billboards.map((bb) => {
        let latlng = new this.google.maps.LatLng(+bb.latitude, +bb.longitude);

        this.bounds.extend(latlng);

        let bbMarker = new this.google.maps.Marker({
          position: latlng,
          icon: {
            url: "/images/billboardColored.png",
            scaledSize: new this.google.maps.Size(25, 25),
          },
        });

        // set listener
        this.google.maps.event.addListener(bbMarker, "click", async () => {
          this.currentBillboard = bb;

          // position of the Popup window
          this.infoWindowPos = {
            lat: this.currentBillboard.latitude,
            lng: this.currentBillboard.longitude,
          };

          // open info window
          this.infoWinOpen = true;

          //calculate score
          // this.getBBScore();
        });

        return bbMarker;
      });

      this.bbClusterer.addMarkers(this.bbMarkers);
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);

      this.loading = false;
    },
  },

  async mounted() {
    // Therefore we need to write googleMap.$mapPromise.then(() => ...)
    this.map = await this.$refs.googleMap.$mapPromise;

    // lazy loading
    // google maps api library is loaded asynchronously , thus is not immediately available even when the page is loaded.
    this.api = await this.$gmapApiPromiseLazy();
    this.apiLoaded = !!this.api;

    // Billboard cluster
    this.bbClusterer = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });

    this.plotBillboards();
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 70vh;
}
#infoWindow {
  max-width: 350px;
  margin: 10px;
}
.images {
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.2s;
}
.image {
  cursor: pointer;
  margin: 5px;
}
.image:hover {
  transform: scale(1.07);
  transition: all 0.2s;
}
</style>
